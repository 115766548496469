import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from 'src/Firebase/firebase-config'

export const ownersApi = createApi({
  reducerPath: 'ownersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL || 'http://localhost:8000/api'}`,
    prepareHeaders: async (headers) => {
      const token = await auth.currentUser.getIdToken()
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Owners'],
  endpoints: (builder) => ({
    getAllOwners: builder.query({
      query: (params) => ({
        url: '/owners',
        params,
      }),
      providesTags: ['Owners'],
    }),
    addOwner: builder.mutation({
      query: (value) => ({
        url: '/owners',
        method: 'POST',
        body: value,
      }),
      invalidatesTags: ['Owners'],
    }),
    deleteOwner: builder.mutation({
      query: (value) => ({
        url: '/owners',
        method: 'DELETE',
        body: value,
      }),
      invalidatesTags: ['Owners'],
    }),
    editOwner: builder.mutation({
      query: (value) => ({
        url: '/owners',
        method: 'PUT',
        body: value,
      }),
      invalidatesTags: ['Owners'],
    }),
  }),
})

export const {
  useAddOwnerMutation,
  useDeleteOwnerMutation,
  useGetAllOwnersQuery,
  useEditOwnerMutation,
} = ownersApi
