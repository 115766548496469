import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from 'src/Firebase/firebase-config'

export const cacheApi = createApi({
  reducerPath: 'cacheApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL || 'http://localhost:8000/api'}`,
    prepareHeaders: async (headers) => {
      const token = await auth.currentUser.getIdToken()
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),

  endpoints: (builder) => ({
    deleteCache: builder.mutation({
      query: () => ({
        url: '/cache',
        method: 'POST',
      }),
    }),
  }),
})

export const { useDeleteCacheMutation } = cacheApi
