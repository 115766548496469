import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from 'src/Firebase/firebase-config'

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL || 'http://localhost:8000/api'}`,
    prepareHeaders: async (headers) => {
      const token = await auth.currentUser.getIdToken()
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Clients'],
  endpoints: (builder) => ({
    getAllClients: builder.query({
      query: (params) => ({
        url: '/clients',
        params,
      }),
      providesTags: ['Clients'],
    }),
    addClient: builder.mutation({
      query: (value) => ({
        url: '/clients',
        method: 'POST',
        body: value,
      }),
      invalidatesTags: ['Clients'],
    }),
    deleteClient: builder.mutation({
      query: (value) => ({
        url: '/clients',
        method: 'DELETE',
        body: value,
      }),
      invalidatesTags: ['Clients'],
    }),
    editClient: builder.mutation({
      query: (value) => ({
        url: '/clients',
        method: 'PUT',
        body: value,
      }),
      invalidatesTags: ['Clients'],
    }),
  }),
})

export const {
  useAddClientMutation,
  useDeleteClientMutation,
  useGetAllClientsQuery,
  useEditClientMutation,
} = clientsApi
