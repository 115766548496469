import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'loggedInUser',
  initialState: {
    currentUser: null,
    loadingAuthState: true,
    notifications: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload
    },
    setLoadingAuthState: (state, action) => {
      state.loadingAuthState = action.payload
    },
    setNotification: (state, action) => {
      state.notifications = action.payload.reverse()
    },
    setNotificationRead: (state, action) => {
      state.notifications = action.payload.reverse()
    },
  },
})

export const {
  setUser,
  setLoadingAuthState,
  setNotification,
  setLoadingNotifications,
  setNotificationRead,
} = userSlice.actions

export default userSlice.reducer
