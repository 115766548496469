import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const versionApi = createApi({
  reducerPath: 'versionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL || 'http://localhost:8000/api'}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.currentUser?.token
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Versions'],
  endpoints: (builder) => ({
    getAllVersions: builder.query({
      query: (params) => ({
        url: '/versions',
        params,
      }),
      providesTags: ['Versions'],
    }),
    addVersion: builder.mutation({
      query: (value) => ({
        url: '/versions',
        method: 'POST',
        body: value,
      }),
      invalidatesTags: ['Versions'],
    }),
    deleteVersion: builder.mutation({
      query: (value) => ({
        url: '/versions',
        method: 'DELETE',
        body: value,
      }),
      invalidatesTags: ['Versions'],
    }),
    editVersion: builder.mutation({
      query: (value) => ({
        url: '/versions',
        method: 'PUT',
        body: value,
      }),
      invalidatesTags: ['Versions'],
    }),
  }),
})

export const {
  useAddVersionMutation,
  useDeleteVersionMutation,
  useGetAllVersionsQuery,
  useEditVersionMutation,
} = versionApi
