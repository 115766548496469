import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from '@firebase/firestore'
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  OAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { auth, db } from './firebase-config'

const saveUserToDb = async (email, uid, displayName) => {
  try {
    const docRef = doc(db, 'users', uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      await updateDoc(docRef, {
        lastLogin: Timestamp.now(),
      })
    } else {
      await setDoc(doc(db, 'users', uid), {
        displayName,
        email,
        uid,
        refresh: false,
        role: 'disabled',
        created: Timestamp.now(),
        lastLogin: Timestamp.now(),
      })
    }
  } catch (error) {
    throw new Error(error)
  }
}
export const updateUserRole = async (roleSelected, uid) => {
  const userRef = doc(db, 'users', uid)
  try {
    await setDoc(userRef, { role: roleSelected, refresh: true }, { merge: true })
    return { success: true }
  } catch (e) {
    throw new Error(e)
  }
}

export const getAllUsers = async () => {
  const querySnapshot = await getDocs(collection(db, 'users'))
  const arr = []
  querySnapshot.forEach((doc) => {
    arr.push(doc.data())
  })
  return arr
}

export const listenForUserChanges = (setUsers, setLoading, lastUser) => {
  const q = query(collection(db, 'users'))
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let users = []
    querySnapshot.forEach((doc) => users.push(doc.data()))
    setUsers(users)
    setLoading(false)
  })
  return { unsubscribe }
}

export const fetchInitialUsers = async () => {
  try {
    const q = query(collection(db, 'users'), orderBy('displayName'), limit(10))
    const querySnapshot = await getDocs(q)
    const arr = []
    querySnapshot.forEach((doc) => {
      arr.push(doc.data())
    })
    const lastKey =
      querySnapshot.docs[querySnapshot.docs.length - 1].data().displayName
    return { arr, lastKey }
  } catch (e) {
    console.log(e)
  }
}

export const fetchNextUsers = async (lastUser) => {
  if (lastUser) {
    try {
      const q = query(
        collection(db, 'users'),
        orderBy('displayName'),
        startAfter(lastUser),
        limit(10)
      )
      const querySnapshot = await getDocs(q)
      const arr = []
      querySnapshot.forEach((doc) => {
        arr.push(doc.data())
      })
      const lastKey = arr[9]?.displayName
      return { arr, lastKey }
    } catch (e) {
      console.log(e)
    }
  }
}
export const fetchInitialNotifications = async (uid, unRead) => {
  try {
    let q = query(
      collection(db, 'users', uid, 'notifications'),
      orderBy('created', 'desc'),
      limit(10)
    )
    if (unRead) {
      q = query(
        collection(db, 'users', uid, 'notifications'),
        where('isRead', '==', false),
        orderBy('created', 'desc'),
        limit(10)
      )
    }
    const querySnapshot = await getDocs(q)
    const arr = []
    querySnapshot.forEach((doc) => {
      arr.push({ ...doc.data(), id: doc.id })
    })
    const lastKey = arr[9]?.created
    return { arr, lastKey }
  } catch (e) {
    console.log(e)
  }
}

export const fetchNextNotifications = async (uid, lastNotification, unRead) => {
  if (lastNotification) {
    try {
      let q = query(
        collection(db, 'users', uid, 'notifications'),
        orderBy('created', 'desc'),
        startAfter(lastNotification),
        limit(10)
      )
      if (unRead) {
        q = query(
          collection(db, 'users', uid, 'notifications'),
          where('isRead', '==', false),
          orderBy('created', 'desc'),
          startAfter(lastNotification),
          limit(10)
        )
      }
      const querySnapshot = await getDocs(q)
      const arr = []
      querySnapshot.forEach((doc) => {
        arr.push({ ...doc.data(), id: doc.id })
      })
      const lastKey = arr[9]?.created
      return { arr, lastKey }
    } catch (e) {
      console.log(e)
    }
  }
}

export const listenUserAnalists = (setUsers) => {
  const q = query(collection(db, 'users'))
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let users = []
    querySnapshot.forEach((doc) => {
      if (doc.data().role === 'analyst') users.push(doc.data())
    })
    setUsers(users)
  })
  return { unsubscribe }
}
/* getAllUsersByRole(["team leader", "analyst"]) */
export const getAllUsersByRole = async (role) => {
  let q = query(collection(db, 'users'))
  if (role) q = query(collection(db, 'users'), where('role', 'in', role))
  const snapshot = await getDocs(q)
  const arr = []
  snapshot.forEach((doc) => {
    const { email, displayName, role, uid } = doc.data()
    arr.push({ email, displayName, role, uid })
  })
  return arr
}

export const getAnalyst = async (id) => {
  if (id) {
    const docRef = doc(db, 'users', id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      return docSnap.data()
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!')
    }
  }
}

export const register = async (userEmail, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, userEmail, password)
    const { user } = res
    const { email, emailVerified, uid, displayName } = user
    saveUserToDb(email, emailVerified, uid, displayName)
  } catch (err) {
    throw new Error(err)
  }
}

// usuarios

// registro de usuario
// asigna rol de usuario

// plataformas

// asigna plataforma a usuario
// agrega un comentario a una plataforma
// mueve status (review, completed)

// props notificaciones

// fromID
// fromName
// toID
// type
// message
// read
// created

// props type platforms

// platformID
// platformCode

export const createNotification = async (currentUser, data, type, step) => {
  const { id: fromId, name: fromName } = currentUser
  switch (type) {
    case 'PLATFORMS_STEP':
      {
        const { Assignee, CreatedBy, PlatformCode, _id: PlatformId } = data.response
        const to = fromId === Assignee ? CreatedBy : Assignee
        const ref = collection(db, 'users', to, 'notifications')
        const message = `has moved ${PlatformCode} to ${step}.`
        await addDoc(ref, {
          fromId,
          type,
          fromName,
          message,
          isRead: false,
          created: Timestamp.now(),
          PlatformCode,
          PlatformId,
        })
      }
      return
    case 'PLATFORMS_COMMENT_ADD':
      {
        const { CreatedBy, PlatformCode, _id: PlatformId, Assignee } = data.response
        const to = fromId === Assignee ? CreatedBy : Assignee
        const message = `commented on ${PlatformCode}: ${data.comment}`
        const ref = collection(db, 'users', to, 'notifications')
        await addDoc(ref, {
          fromId,
          type,
          fromName,
          message,
          isRead: false,
          created: Timestamp.now(),
          PlatformCode,
          PlatformId,
        })
      }
      return
    case 'PLATFORMS_COMMENT_LIKE':
      return
    case 'PLATFORMS_ASSIGNMENT':
      {
        const { Assignee, PlatformCode, _id: PlatformId } = data
        /* if (fromId !== Assignee) { */
        const ref = collection(db, 'users', Assignee, 'notifications')
        const message = `has assigned you ${PlatformCode}.`
        await addDoc(ref, {
          fromId,
          type,
          fromName,
          message,
          isRead: false,
          created: Timestamp.now(),
          PlatformCode,
          PlatformId,
        })
        /* } */
      }
      return
    case 'USER_ROLE_CHANGE':
      {
        const { role, user } = data
        const message = `has changed your role to ${role}. If you can't see the change, please log out and log in again.`
        const ref = collection(db, 'users', user.uid, 'notifications')
        await addDoc(ref, {
          fromId,
          type,
          fromName,
          message,
          isRead: false,
          created: Timestamp.now(),
        })
      }
      return
    case 'USER_NEW':
      return
    default:
      return
  }
  /* if (type === 'NOTIFICATIONS_PLATFORMS') {
    await addDoc(ref, {
      fromId,
      type,
      fromName,
      message,
      isRead: false,
      created: Timestamp.now(),
      PlatformCode,
      PlatformId,
    })
  } else if (type === 'NOTIFICATIONS_USERS') {
    await addDoc(ref, {
      fromId,
      type,
      fromName,
      message,
      isRead: false,
      created: Timestamp.now(),
    })
  } */
}

export const listenForNotifications = (setNotifications, setLoading, uid) => {
  const q = query(collection(db, 'users', uid, 'notifications'))
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let notifications = []
    querySnapshot.forEach((doc) => {
      notifications.push({ ...doc.data(), id: doc.id })
    })
    setNotifications(notifications)
    setLoading(false)
  })
  return { unsubscribe }
}

/* export const fetchInitialNotifications = async (uid) => {
  try {
    const q = query(
      collection(db, 'users', uid, 'notifications'),
      orderBy('created', 'desc'),
      limit(10)
    )
    const querySnapshot = await getDocs(q)

    const arr = []
    querySnapshot.forEach((doc) => {
      arr.push({ ...doc.data(), id: doc.id })
    })

    const lastKey = querySnapshot.docs[querySnapshot.docs.length - 1].data().created
    return { arr, lastKey }
  } catch (e) {
    console.log(e)
  }
}
 */
/* export const fetchNextNotifications = async (lastNotification, uid) => {
  if (lastNotification) {
    try {
      const q = query(
        collection(db, 'users', uid, 'notifications'),
        orderBy('created', 'desc'),
        startAfter(lastNotification),
        limit(10)
      )
      const querySnapshot = await getDocs(q)
      const arr = []
      querySnapshot.forEach((doc) => {
        arr.push({ ...doc.data(), id: doc.id })
      })
      const lastKey =
        querySnapshot.docs[querySnapshot.docs.length - 1].data().created
      console.log({ lastKey })
      console.log({ arr })
      return { arr, lastKey }
    } catch (e) {
      console.log(e)
    }
  }
}
 */
export const listenForNotificationNumber = (setNotificationNumber, uid) => {
  const q = query(
    collection(db, 'users', uid, 'notifications'),
    where('isRead', '==', false),
    limit(1)
  )

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const size = querySnapshot.size
    setNotificationNumber(size)
  })
  return { unsubscribe }
}

export const PaginationNotification = async (uid) => {
  const q = query(collection(db, 'users', uid, 'notifications'))
  const querySnapshot = await getDocs(q)
  const size = await querySnapshot.size
  return { totalPages: Math.ceil(size / 10) }
}

export const markAsRead = async (uid, notificationId) => {
  const ref = doc(db, 'users', uid, 'notifications', notificationId)
  await updateDoc(ref, { isRead: true })
}
export const markAsReadAll = async (uid, setLoad) => {
  setLoad(true)
  const batch = writeBatch(db)
  const q = query(
    collection(db, 'users', uid, 'notifications'),
    where('isRead', '==', false),
    limit(499)
  )
  try {
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((notification) => {
      const docRef = doc(db, 'users', uid, 'notifications', notification.id)
      batch.update(docRef, { isRead: true })
    })
    await batch.commit()
    setLoad(false)
  } catch (e) {
    console.log(e)
    setLoad(false)
  }
}

export const markAllAsRead = async (uid) => {
  const batch = writeBatch(db)
  const q = query(
    collection(db, 'users', uid, 'notifications'),
    where('isRead', '==', false),
    limit(499)
  )
  const querySnapshot = await getDocs(q)
  console.log(querySnapshot.size)
  querySnapshot.forEach((notification) => {
    const docRef = doc(db, 'users', uid, 'notifications', notification.id)
    batch.update(docRef, { isRead: true })
  })
  await batch.commit()
}

export const logout = async () => {
  await signOut(auth)
}

const provider = new OAuthProvider('microsoft.com')
provider.setCustomParameters({ tenant: process.env.REACT_APP_AZURE_ID })

export const loginMicrosoft = async () => {
  try {
    const result = await signInWithPopup(auth, provider)
    const { isNewUser } = getAdditionalUserInfo(result)
    if (isNewUser) {
      const { email, uid, displayName } = result.user
      await saveUserToDb(email, uid, displayName)
      const allUsers = await getAllUsersByRole(['team leader', 'admin'])
      allUsers.forEach(async (userOfList) => {
        await createNotification(
          uid,
          displayName,
          userOfList.uid,
          'NOTIFICATIONS_USERS',
          `${displayName.toUpperCase()} created an account.`
        )
      })
    } else {
      return result.user.displayName
    }
  } catch (error) {
    throw new Error(error)
  }
}

export const SendNotification = async (uidReceptor, notification) => {
  const userRef = doc(db, 'users', uidReceptor)
  try {
    await setDoc(userRef, { notification: notification }, { merge: true })
  } catch (e) {
    throw new Error(e)
  }
}

export const createFeedback = async (message, from, imgName = null) => {
  const ref = doc(collection(db, 'feedback'))
  try {
    await setDoc(ref, {
      from,
      message,
      status: 'open',
      id: ref.id,
      imgName,
    })
  } catch (e) {
    throw new Error(e)
  }
}

export const getAllFeedbacks = async () => {
  let q = query(collection(db, 'feedback'))
  const snapshot = await getDocs(q)
  const arr = []
  snapshot.forEach((doc) => {
    arr.push(doc.data())
  })
  return arr
}

export const changeFeedbackStatus = async (id, newStatus) => {
  const ref = doc(db, 'feedback', id)
  try {
    await updateDoc(ref, { status: newStatus })
  } catch (error) {
    throw new Error(error)
  }
}
