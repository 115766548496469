import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from 'src/Firebase/firebase-config'

export const platformApi = createApi({
  reducerPath: 'platformApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL || 'http://localhost:8000/api'}`,
    prepareHeaders: async (headers) => {
      const token = await auth.currentUser.getIdToken()
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['CountryPlatforms', 'Assignees', 'Statistics', 'GlobalPlatforms'],
  endpoints: (builder) => ({
    getCountryPlatform: builder.query({
      query: (id) => `/countryPlatforms/${id}`,
      providesTags: ['CountryPlatforms'],
    }),
    getTableCountryPlatforms: builder.query({
      query: (params) => {
        return {
          url: '/tableCountryPlatforms',
          params,
        }
      },
      providesTags: ['CountryPlatforms', 'Assignees'],
    }),
    getAllCountryPlatforms: builder.query({
      query: ({
        skip,
        sortBy,
        sortOrder,
        assignee,
        keyword,
        createdAt,
        completedAt,
        priority,
        client,
        owner,
        status,
        country,
        limit,
        step,
        grouped,
        ITStatus,
        SubregionCountry,
      }) => {
        return {
          url: '/countryPlatforms',
          params: {
            skip,
            sortBy,
            sortOrder,
            assignee,
            keyword,
            createdAt,
            completedAt,
            priority,
            client: client.join(';'),
            owner: owner.join(';'),
            status: status.join(';'),
            country: country.join(';'),
            limit,
            step: step.join(';'),
            grouped,
            ITStatus,
            SubregionCountry,
          },
        }
      },
      providesTags: ['CountryPlatforms', 'Assignees'],
    }),
    createCountryPlatform: builder.mutation({
      query: (value) => ({
        url: '/countryPlatforms',
        method: 'POST',
        body: value,
      }),
      invalidatesTags: ['CountryPlatforms', 'Assignees', 'Statistics'],
      transformResponse: (response, meta, arg) => response.data,
    }),
    editCountryPlatform: builder.mutation({
      query: (value) => ({
        url: `/countryPlatforms`,
        method: 'PUT',
        body: value,
      }),
      invalidatesTags: ['CountryPlatforms', 'Assignees', 'Statistics'],
    }),
    editManyCountryPlatforms: builder.mutation({
      query: (value) => ({
        url: `/manyCountryPlatforms`,
        method: 'PUT',
        body: value,
      }),
      invalidatesTags: ['CountryPlatforms', 'Assignees', 'Statistics'],
    }),
    editManyCountryPlatforms2: builder.mutation({
      query: (value) => ({
        url: `/manyCountryPlatforms2`,
        method: 'PUT',
        body: value,
      }),
      invalidatesTags: ['CountryPlatforms', 'Assignees', 'Statistics'],
    }),
    deleteCountryPlatform: builder.mutation({
      query: (id) => ({
        url: `/countryPlatforms/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CountryPlatforms', 'Assignees', 'Statistics'],
    }),
    deleteManyCountryPlatforms: builder.mutation({
      query: (ids) => ({
        url: `/manyCountryPlatforms`,
        method: 'DELETE',
        body: ids,
      }),
      invalidatesTags: ['CountryPlatforms', 'Assignees', 'Statistics'],
    }),
    addCountryPlatformComment: builder.mutation({
      query: ({ Message, id }) => ({
        url: `/countryPlatforms/${id}/comments`,
        method: 'POST',
        body: Message,
      }),
      invalidatesTags: ['CountryPlatforms'],
    }),
    editCountryPlatformComment: builder.mutation({
      query: ({ userId, messageId, platformId }) => ({
        url: `/countryPlatforms/${platformId}/comments`,
        method: 'PUT',
        body: { userId, messageId },
      }),
      invalidatesTags: ['CountryPlatforms'],
    }),
    getAssigneeCount: builder.query({
      query: () => ({
        url: '/assigneeCount',
      }),
      providesTags: ['Assignees'],
    }),
    getStatistics: builder.query({
      query: () => ({
        url: '/statistics',
      }),
      providesTags: ['Statistics'],
    }),
    getGlobalPlatforms: builder.query({
      query: () => ({
        url: '/globalPlatforms',
      }),
      providesTags: ['GlobalPlatforms'],
    }),
    editGlobalPlatform: builder.mutation({
      query: (body) => ({
        url: '/globalPlatforms',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['GlobalPlatforms'],
    }),
  }),
})

export const {
  useGetCountryPlatformQuery,
  useGetTableCountryPlatformsQuery,
  useGetAllCountryPlatformsQuery,
  useLazyGetAllCountryPlatformsQuery,
  useEditCountryPlatformMutation,
  useEditManyCountryPlatformsMutation,
  useEditManyCountryPlatforms2Mutation,
  useCreateCountryPlatformMutation,
  useAddCountryPlatformCommentMutation,
  useEditCountryPlatformCommentMutation,
  useDeleteCountryPlatformMutation,
  useDeleteManyCountryPlatformsMutation,
  useGetAssigneeCountQuery,
  useGetStatisticsQuery,
  useEditGlobalPlatformMutation,
  useGetGlobalPlatformsQuery,
} = platformApi
