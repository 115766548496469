import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'

const Welcome = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShow(true)
    }, 150)

    return () => {
      clearTimeout(timerId)
      setShow(false)
    }
  }, [])

  return (
    <div>
      {show ? (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="welcome-container"
            key="welcome-container"
          >
            <img src="/bb_logo.png" alt="logo" className="welcome-logo" />
            <div className="loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </motion.div>
        </AnimatePresence>
      ) : null}
    </div>
  )
}

export default Welcome
