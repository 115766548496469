import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { clientsApi } from './Query/clients'
import { firestoreApi } from './Query/firebase'
import { ownersApi } from './Query/owners'
import { platformApi } from './Query/platforms'
import { usersApi } from './Query/users'
import { versionApi } from './Query/versions'
import { cacheApi } from './Query/cache'

import userReducer from './userSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    [platformApi.reducerPath]: platformApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [ownersApi.reducerPath]: ownersApi.reducer,
    [versionApi.reducerPath]: versionApi.reducer,
    [cacheApi.reducerPath]: cacheApi.reducer,
    [firestoreApi.reducerPath]: firestoreApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(platformApi.middleware)
      .concat(usersApi.middleware)
      .concat(clientsApi.middleware)
      .concat(ownersApi.middleware)
      .concat(versionApi.middleware)
      .concat(cacheApi.middleware)
      .concat(firestoreApi.middleware),

  devTools: process.env.NODE_ENV !== 'production',
})

setupListeners(store.dispatch)
