import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from 'src/Firebase/firebase-config'

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL || 'http://localhost:8000/api'}`,
    prepareHeaders: async (headers) => {
      const token = await auth.currentUser.getIdToken()
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    addCustomClaim: builder.mutation({
      query: (value) => ({
        url: '/setCustomClaims',
        method: 'POST',
        body: value,
      }),
    }),
    editCustomClaim: builder.mutation({
      query: (value) => ({
        url: '/setCustomClaims',
        method: 'PUT',
        body: value,
      }),
    }),
  }),
})

export const { useAddCustomClaimMutation, useEditCustomClaimMutation } = usersApi
