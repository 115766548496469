import { getAllUsersByRole, updateUserRole } from '@firebaseUtils'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'

export const firestoreApi = createApi({
  reducerPath: 'firestoreApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getAllUsersByRole: builder.query({
      async queryFn(role) {
        try {
          const result = await getAllUsersByRole(role)
          return { data: result }
        } catch (e) {
          return { error: e.message }
        }
      },
      providesTags: ['Users'],
    }),
    setUserRole: builder.mutation({
      async queryFn(arg) {
        try {
          const result = await updateUserRole(arg.role, arg.uid)
          return { data: result }
        } catch (e) {
          return { error: e }
        }
      },
      invalidatesTags: ['Users'],
    }),
  }),
})

export const {
  useGetAllUsersByRoleQuery,
  useSetUserRoleMutation,
  useLazyGetAllUsersByRoleQuery,
} = firestoreApi
